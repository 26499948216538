import { IMAGE_BUCKET_URL } from "../../DataStore/ApiConstant";
// import WhatsappButton from "../Cashier/whatsappButton";
import ImportantNotice from "./ImportantNotice";
import TranslatesContent from "../../DataStore/Languages/translatesContent";
import BonusOffer from "../TransactionCommon/BonusOffer";
import { CONTACT_DETAILS } from "../../constants/index";

const ByHandPayment = () => {
  return (
    <div
      className="tab-pane fade"
      id="v-pills-settings"
      role="tabpanel"
      aria-labelledby="v-pills-settings-tab"
    >

          <>
              <div className="row d-flex justify-content-center">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="cashierNewTitel text-center"
                >
                </label>
                <div className="col-md-2 col-4 py-2 text-center">
                <img
                    src={IMAGE_BUCKET_URL + "By-hand-new.png"}
                    class="img-fluid"
                    alt=""
                  ></img>
                </div>
              </div>
              <div className="row">
                <div className="col-12 ">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="row">
                        <div className="col-12">
                          <div className="row text-center">
                            <div className="col-12 mb-2 mb-lg-3">
                              <label
                                htmlFor="exampleFormControlInput1"
                                className="cashierNewTitel"
                              >
                                <TranslatesContent contentKey="by-hand-cash-pickup" />
                              </label>
                            </div>
                            <div className="col-12">
                              <div className="">
                              <a href={`https://wa.me/${CONTACT_DETAILS.deposit.whatsapp}`} target="_blank">
                                  <div className="btn btn-primary">
                                    <i
                                      className="fa-brands fa-whatsapp"
                                      style={{ fontSize: "25px" }}
                                      s
                                    ></i>{" "}
                                    {CONTACT_DETAILS.deposit.whatsapp}
                                  </div>
                                </a>

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>


            <div className="col-lg-12">
              <div className="row">
                <div className="col-12">
                  <div className="row text-center">
                  {/* <div className="row d-flex justify-content-center">
                      <label
                        htmlFor="exampleFormControlInput1"
                        className="cashierNewTitel text-center"
                      >
                        <TranslatesContent contentKey="by-hand-cash-pickup" />
                      </label>
                      <div className="col-md-2 col-4 py-2 text-center">
                        <img
                          src={IMAGE_BUCKET_URL + "By-hand-new.png"}
                          class="img-fluid"
                          alt=""
                        ></img>
                      </div>
                    </div> */}

                    {/* <div className="col-12 mb-2 mb-lg-3">
                      <label
                        for="exampleFormControlInput1"
                        className="cashierNewTitel"
                      >
                        {" "}
                        <TranslatesContent contentKey="by-hand-cash-pickup" />
                      </label>
                    </div> */}

                    {/* <div className="col-md-2 col-4 py-2 text-center">
                    <a href={`https://wa.me/${CONTACT_DETAILS.deposit.whatsapp}`} target="_blank">
                      <img
                        src={IMAGE_BUCKET_URL + "By-hand-new.png"}
                        class="img-fluid zoom"
                        alt=""
                      ></img>
                    </a>
                    </div> */}

                    {/* <div className="col-12">
                        <div className="btn btn-primary">
                        <a href="https://wa.me/+94765992992" target="_blank">
                          <i
                            className="fa-brands fa-whatsapp"
                            style={{ fontSize: "25px" }}
                          ></i>{" "}
                          +94 770 000 000
                          </a>
                        </div>
                      
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
        {/* <WhatsappButton /> */}
      </div>
  );
};

export default ByHandPayment;
