import TranslatesContent from "../../../DataStore/Languages/translatesContent";

const CustomerVerification = () => {
    return ( 
        <div class="row">
        <div class="col-12 mainTitel">CUSTOMER VERIFICATION </div>

        <div class="col-12">
        It is important that we are able to positively identify our customers to comply with both our regulatory requirements and to help ensure the security of accounts. As such, we may be required to conduct enhanced verification following our Verification process.<br/><br/>
        We will contact you to update our records and will let you know what information or documentation we require.<br/><br/>
        In order to comply with our regulatory requirements, it is important that we take steps to help ensure that gambling is fair and open, not linked to crime and does not lead to harm.<br/><br/>
        This means that at times we need to ask you for your assistance to be able to confirm your identity and show that you have sufficient funds to support your gambling activity. <br/><br/>
        In order to verify your identity, we may ask you to provide a copy of photographic ID such as a copy of your passport, ID card, driving licence or a document confirming your residential address such as a utility bill. <br/><br/>
        All copies of personal identification must contain photographic ID and should clearly display your full name, date of birth and have a valid document expiry date. Please note, if your identity document has an expiry date on the back this will also need to be included.<br/><br/>
        We may need you to submit us a selfie holding the identity document to verify that the online user and the identity proof is same. <br/><br/>

        As an enhance verification process, we may ask you to provide additional information and documentation that shows you have sufficient funds to support your gambling activity. 
        </div>
    </div>
    );
}
 
export default CustomerVerification;