import { useState } from "react";
import CountryListSelect from "../CountryListSelect";
import MobileField from "../MobileFIeld";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import ForgotPasswordBtn from "./ForgotPasswordBtn";
import {
  FORGOT_PASSWORD_URL,
  RECAPTCHA_SITEKEY,
} from "../../../DataStore/ApiConstant";
import axios from "axios";
import TranslatesContent from "../../../DataStore/Languages/translatesContent";

const ForgotPassword = ({ changeContent }) => {
  const [country, setCountry] = useState("LK-94");
  const [phoneNumber, setPhone] = useState("");
  const [forgetErr, setForgetErr] = useState("");

  const countrySelected = (e) => {
    setCountry(e.target.value);
  };

  const updatePhone = (e) => {
    setPhone(e.target.value);
  };
  let countryValue = country.split("-")[1];
  const requestNewPassword = (e, token) => {
    e.preventDefault();
    axios
      .get(`${FORGOT_PASSWORD_URL}?code=${countryValue}&contact=${phoneNumber}&token=${token}`)
      .then((resp) => {
        if (resp.data === "done") {
          changeContent("forgotpasswordsuccess");
        } else {
          setForgetErr(resp.data);
        }
      })
      .catch((err) => {});
  };

  return (
    <div className="register-form ">
      <div className="text-center register-header">
        <i className="fas fa-lock fa-4x"></i>
        <h2 className="mainTitel">
          <TranslatesContent contentKey="forgot-password" />
        </h2>
      </div>

      <form action="">
        <div className="mb-2">
          <label htmlFor="">
            <TranslatesContent contentKey="join-now-country" />*
          </label>
          <CountryListSelect
            selectedCountry={country}
            countrySelected={countrySelected}
          />
        </div>
        <div className="mb-2">
          <MobileField
            country={country}
            updatePhone={updatePhone}
            phoneNumber={phoneNumber}
          />
        </div>
        <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_SITEKEY}>
          <ForgotPasswordBtn requestNewPassword={requestNewPassword} />
        </GoogleReCaptchaProvider>
      </form>
      <div className="error-wrapper" style={{ color: "red" }}>
        {forgetErr && forgetErr}
      </div>
    </div>
  );
};

export default ForgotPassword;
