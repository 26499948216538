import { useEffect,useState } from "react";
import { useTableConfigNotice } from "../../services/config"
import { IMGBUCKETURL } from "../../DataStore/ApiConstant";


const PoyaBanneer = () => {
  let poya = useTableConfigNotice()
  const [active, setActive] = useState(true);
  useEffect(() => {
    !poya.isLoading && poya?.data?.length > 0 && setActive(true)
    },[poya.isLoading])
    return (
        <>
    <div >
        {
        !poya.isLoading && poya?.data?.length > 0 && active &&(
            <div className={"modal showModal"}  tabindex="-1"   aria-labelledby="exampleModalLabel"
            style={{
              backgroundColor: "#000000a1",
            }}  >
            <div className={"modal-dialog modal-dialog-scrollable  modal-lg showModalcontent"}>
              <div className="modal-content" style={{backgroundColor:'transparent'}}>
              <div className="modal-header">
                <button
                  type="button" className="btn-close" onClick={(e) => {setActive(false) }} style={{ backgroundColor: "white", position: "absoulute"}} > 
                </button>
              </div>
                <div className="modal-body">

                  <div style={{
                      width:"100%",
                      background: `url(${IMGBUCKETURL+poya.data[0].image})`,
                      backgroundSize: '100%',
                      height: '400px',
                      backgroundPosition: 'center',
                      backgroundRepeat: 'no-repeat',
                    }}>
                  </div>
                </div>
                <div className="modal-footer"></div>
              </div>
            </div>
          </div>
        )
        
        }
    </div>

        </>
    
  );
};

export default PoyaBanneer;
