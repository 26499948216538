import React from 'react';
import axios from "axios";
import { useState } from "react";
import {PASSWORD_CHANGE} from "../../../DataStore/ApiConstant";

const ChangePasswordModal = ({ isOpen, onClose, close }) => {
    const [oldpass, setOldpass] = useState("");
    const [newpass, setNewpass] = useState("");
    const [verifyPass, setVerifyPass] = useState("");

    const [passErr, setPassErr] = useState("");
    const [passSuccess, setPassSuccess] = useState("");
    const removeError = () => {
        setTimeout(() => {
            setPassErr("")
          }, 3000);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const data = { oldpass: oldpass, newpass: newpass, varifyPass: verifyPass };
        // alert(oldpass,newpass,verifyPass)
        if(oldpass=="" || newpass=="" || verifyPass==""){
            setPassErr("Please fill all required fields")
            removeError()
            return 

        }
        if(newpass !== verifyPass){
           setPassErr("Password and Confirm passwords are not same")
           removeError()
           return
        }
        axios
        .post(PASSWORD_CHANGE, data)
        .then((resp) => {
          console.log(resp.data);
          if (resp.data === "Invalid old password") {
            setPassErr("Current password that you entered is incorrect");
            removeError();
          } else if (resp.data === "done") {
            setOldpass("");
            setNewpass("");
            setVerifyPass("");
            setPassSuccess("Password changed successfully");
            setTimeout(() => {
              setPassSuccess("");
              onClose();
            }, 3000);
          }
        })
        .catch((err) => {
          console.log(err);
        });
      }
  return (
    <div className={`modal showModal  ${isOpen ? 'show' : ''}`} style={{ display: isOpen ? 'block' : 'none' }} >
      <div className=" modal-dialog modal-dialog-scrollable  modal-md showModalcontent">
        <div className="modal-content popupBg">
          <div className="modal-header">
          {
              !close ? (<button type="button" className="btn-close" onClick={onClose} data-bs-dismiss="modal" aria-label="Close" style={{ backgroundColor: "white" }}>
            </button>) : ("")
            }
          </div>
          <div className="modal-body">
            {/* <h5 className="modal-title mainTitel">Change Password</h5> */}
            <div class="row">
                <div class="col-12 mainTitel">Change Password</div>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="currentPassword"style={{color:"white"}}>Current Password</label>
                <input type="password" className="form-control" id="currentPassword" name="currentPassword" value={oldpass} onChange={(e) => {setOldpass(e.target.value);}} />
              </div>
              <div className="form-group">
                <label htmlFor="newPassword" style={{color:"white"}}>New Password</label>
                <input type="password" className="form-control" id="newPassword" name="newPassword" value={newpass} onChange={(e) => {setNewpass(e.target.value);}}/>
              </div>
              <div className="form-group">
                <label htmlFor="confirmNewPassword"style={{color:"white"}}>Confirm New Password</label>
                <input type="password" className="form-control" id="confirmNewPassword" name="confirmNewPassword" value={verifyPass} onChange={(e) => {setVerifyPass(e.target.value);}}/>
              </div>
              <button type="submit" className="btn btn-primary">Change Password</button>
              {
                !close ? (<button type="button" className="btn btn-secondary" onClick={onClose}>Cancel</button>) : ("")
              }
              
            </form>
        <div className="login-err" style={{ color: "red", fontSize: "14px" }}>
          {passErr && passErr}
        </div>
        <div className="loginSuccess" style={{ color: "green", fontSize: "14px" }}>
          {passSuccess && passSuccess}
        </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePasswordModal;
