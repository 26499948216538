import TranslatesContent from "../../DataStore/Languages/translatesContent";
import { IMAGE_BUCKET_URL } from "../../DataStore/ApiConstant";

const GameTabs = ({ games, getKey = false, slot=false }) => {
  let gamesLength = Object.keys(games).length;
  let colNum = 2;

  if (12 % gamesLength === 0) {
    colNum = 12 / gamesLength;
  }

  return (
    <ul className="nav nav-tabs" id="myTab" role="tablist">
      {games &&
        Object.keys(games).map((gameky, ky) => {
          const gmeId = gameky.trim().replace(/ /g, "");
          return (
            // <li  className={`nav-item col-${colNum}`}  role="presentation"  key={gameky}  onClick={()=>getKey ? getKey(gameky) :console.log('keyCheck') }>
            <li  className={`nav-item col`}  role="presentation"  key={gameky}  onClick={()=>getKey ? getKey(gameky) :console.log('keyCheck') }>
              <button className={"nav-link" + (ky === 0 ? " active" : "")}  id={gameky.toUpperCase() + "-tab"} data-bs-toggle="tab"
                data-bs-target={"#" + gmeId}
                type="button"
                role="tab"
                aria-controls={gameky.toUpperCase()}
                aria-selected="true"  >
                <img src={IMAGE_BUCKET_URL + games[gameky].image}  className="img-fluid mx-auto d-block pt-3"  alt=""  />
                {slot ? gameky.replace("_", "-").toUpperCase():<TranslatesContent contentKey={gameky.replace("_", "-")} />}
              </button>
            </li>
          );
        })}
    </ul>
  );
};

export default GameTabs;
