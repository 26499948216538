import { useEffect, useRef, useState } from "react";
import { useGameBoardResults } from "../../../services/gameBoards";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const DragonTigerBoard = ({config, isLoad}) => {
  
  const svgRef = useRef();
  const [gameResult, setGameResult] = useState({});
  const [gemeConfig, setGameConfig] = useState([]);

  const { data, isLoading, status} = useGameBoardResults('drt');
  const loadGame = (gameLink) => {
    window.location.replace("/livedragontiger/#" + gameLink);
  };
  useEffect(() => {
    if(!isLoad ){
      if(config ){
          let data = Object.values(config)
          // console.log(data[2]?.drt[1][1]);
          setGameConfig(data[2]?.drt[1][1] || []);
      }
    }
  },[isLoad])
  useEffect(() => {
    const handleLoad = () => {
      if(!isLoading && status && Object.keys(data).length > 0){
      let rows = 6;
      let countVal = 0;
          setGameResult(data)
          if(svgRef.current){
            let svgObj_mobile = svgRef.current.contentDocument;
                  if(Object.keys(data).length > 0){
    
                    Object.keys(data.drt1).forEach(function (pattern,k) {
                      // console.log(pattern);
                    if ( pattern === "bigRoad" || pattern === "bigEyeBoy" || pattern === "smallRoad" || pattern === "cockroach" || pattern === "beadPlate" ) {
                      data.drt1[pattern].forEach(function (res, k) {
          
                        let cellCount = k + 1;
                        res.forEach(function (j) {
                          // console.log('test',j);
                            if (j !== "") {
                              if (svgObj_mobile != null) {
                                if(svgObj_mobile.getElementById( `${pattern}_${cellCount}` )){
                                  let restOne = svgObj_mobile.getElementById( `${pattern}_${cellCount}` );
                                  if (restOne != null) {
                                      restOne.setAttribute("xlink:href", `#${pattern}_${j}`);
                                    }
                                     else {
                                      restOne.setAttribute("xlink:href", `#none`);
                                    }
                                }
                                }
                              }
                              cellCount += rows;
                        });
                      })
                    }else if (pattern === "predictResult") {
                      let predictPattern = ["bigEyeBoy", "smallRoad", "cockroach"];
                      data.drt1[pattern].forEach(function (results, i) {
                        let bp = results[0];
                        results.shift();
                        results.forEach(function (result, j) {
                          if (result) {
                            if (svgObj_mobile != null) {
                              if(svgObj_mobile.getElementById(`predictor_${bp}_${j}`)){
                                let restOne = svgObj_mobile.getElementById(`predictor_${bp}_${j}`);
                                restOne.setAttribute("xlink:href",`#${predictPattern[j]}_${result}`);
                              }
                            }
                          }
                        });
                      });
                    }
                    else if (pattern === "resultCount") {
                      let count = data.drt1[pattern];
                      if (svgObj_mobile != null) {
                        if(svgObj_mobile.getElementById("statictics-b")) svgObj_mobile.getElementById("statictics-b").textContent = count?.d || 0;
                        if(svgObj_mobile.getElementById("statictics-p")) svgObj_mobile.getElementById("statictics-p").textContent = count?.t || 0;
                        if(svgObj_mobile.getElementById("statictics-t")) svgObj_mobile.getElementById("statictics-t").textContent = count?.tie || 0;
                      }
                    }
                    //not nessasary
                    countVal++;
                    })
  
                  }
          }
              }
          }

    const svgElement = svgRef.current;

    if (svgElement) {
      if (svgElement.contentDocument) {
        // handleLoad();
        setTimeout(() => {
          handleLoad();
        },1000);
      } else {
        svgElement.addEventListener('load', handleLoad);
      }
    }

    return () => {
      if (svgElement) {
        svgElement.removeEventListener('load', handleLoad);
      }
    };
  }, [data]);


  return (
        <div className="col-lg-4 col-lg-4 mx-0 px-1 mb-2" style={{ position: "relative", cursor:"pointer"}} onClick={() => { if (gemeConfig && gemeConfig[4] !== 0) { loadGame("drt1")}}}>
              {
                 gemeConfig && gemeConfig[4]==0 ? (
                 <div class="position-absolute top-50 start-50 translate-middle d-flex flex-column justify-content-center align-items-center tableClose tableCloseText">
                    <div class="row ">
                        <div class="tableCloseText ">Closed
                        </div>
                    </div>
                </div>) : 
              (
                <div class="content-overlay text-center pt-5">
                    <a class="hoverButton " onClick={() => loadGame("drt1")}>
                      PLAY
                    </a>
                </div>
              )
              }
          {/* <Link to={`/livedragontiger/#drt1`} className="single-board-link"> */}
            {/* <div class="content-overlay">
              <p style={{ textAlign: "center", marginTop: "10%" }}>PLAY</p> */}
            {/* <div class="content-overlay text-center pt-5 ">
            <a class="hoverButton " onClick={() => loadGame("drt1")}>
              PLAY
            </a> */}
              {/* <a class="hoverButton" style={{ textAlign: "center", marginTop: "10%" }}>PLAY</a> */}
            {/* </div> */}
          {/* </Link> */}
          <div className="row m-0 p-1 border rounded border-white">
            <div className="col-12 ms-0 p-0">
                <object ref={svgRef} data={"/assets/img/gameResultBoards/dt_mobile_board.svg"}></object>
            </div>
            <div className="col-12">
              <div className="row">
                <div className="col-4 tableCountBoardName" style={{ color: "white" }}>
                  {/* {gameResult.drt1 && gameResult?.drt1.tablename || 'N/A'} */}
                  {gemeConfig ? gemeConfig[1]  : "N/A"}
                </div>
                <div className="col-8 " style={{ paddingRight: 0 }}>
                  <div className="tableCountBoardLimit text-end" style={{ paddingRight: 0, textDecoration: "none" }}>
                    {/* {new Intl.NumberFormat().format(
                        gameResult.and1.min
                      )}
                    -{" "}
                    {new Intl.NumberFormat().format(
                        gameResult.and1.max
                      )} */}
                      {/* {new Intl.NumberFormat().format(
                        300
                      )}
                    -{" "}
                    {new Intl.NumberFormat().format(
                        50000
                      )} */}
                      {gemeConfig ? gemeConfig[2]  : 0}
                      -{" "}
                      {gemeConfig ? gemeConfig[3] : 0}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
  );
};

export default DragonTigerBoard;
