import ForgotPassword from "../ForgotPassword/ForgotPassword";
import ForgotPasswordSucess from "../ForgotPassword/ForgotPasswordSucess";
import LoginForm from "./LoginForm";
import RegisterFormWrapper from "./Register/RegisterFormWrapper";
import { useEffect, useRef, useState } from "react";

const LoginModalWrapper = ({ type, status, closePopup, changeContent, openGeneralModal, zIndex = 1050 }) => {
  const [display, setDisplay] = useState("none");
  const [opacity, setOpacity] = useState(1);

  let modal = document.getElementById("loginpopup");

  const handleModalClose = () => {
    setDisplay("none");
    setOpacity(0);
    closePopup();
  };

  useEffect(() => {
    if (status === "active") {
      setDisplay("active");
      setTimeout(() => setOpacity(1), 10);
    }
  }, [status]);

  return (
    display === "active" && (
      <div
        className="modal showModal"
        id="loginpopup"
        style={{
          backgroundColor: "#000000a1",
          zIndex, // Dynamic zIndex for stacking
        }}
      >
        <div className="modal-dialog showModalcontent">
          <div className="modal-content popupBg">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close"
                onClick={(e) => {
                  e.preventDefault();
                  handleModalClose();
                }}
                aria-label="Close"
                style={{ backgroundColor: "white" }}
              ></button>
            </div>
            <div className="modal-body">
              {type === "login" ? (
                <LoginForm openGeneralModal={openGeneralModal} changeContent={changeContent} closePopup={closePopup} />
              ) : type === "register" ? (
                <RegisterFormWrapper openGeneralModal={openGeneralModal}  />
              ) : type === "password-reset" ? (
                <ForgotPassword changeContent={changeContent} />
              ) : (
                <ForgotPasswordSucess />
              )}
            </div>
          </div>
        </div>
      </div>
    )
  );
};


export default LoginModalWrapper;
