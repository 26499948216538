import axios from "axios";
import { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

import {
  BASE_URL,
  FUND_TRANSFER_TYPE_URL,
  SPORT_LOGIN_URL,
  POINTS_URL,
  IMAGE_BUCKET_URL,
  END_ROLLING
} from "../../DataStore/ApiConstant";
import { connect } from "react-redux";
import _ from "lodash";
import TranslatesContent from "../../DataStore/Languages/translatesContent";
import { usePointsDetails } from "../../services/cashier";
import { usePromotions } from "../../services/promotions";
import { promotion } from "../../DataStore/atom";
import { useAtom } from "jotai";
import { useRolling } from "../../services/cashier";

//   useEffect(() => {

//   }, []);
const Rolling = ({setSelectedGameType}) => {

const rolling = useRolling();

const [errmsg, setErrmsg] = useState("");
const [successMsg, setSuccessMsg] = useState("");
// console.log(rolling?.data);
const endRolling = (e) => {
  e.preventDefault();
  axios
  .get(END_ROLLING)
  .then((resp) => {
    console.log(resp.data);
    if(resp.data.resp == "OK"){
      setSuccessMsg("Your rolling amount will be claim withing few minutes")
      setTimeout(() => {
        setSuccessMsg("");
      }, 3000);
    }
    else{
      setErrmsg("Something went wrong please try againg later")
      setTimeout(() => {
        setErrmsg("");
      }, 3000);
    }
  })
  .catch((err) => {
    console.error("error", err)
  });
}
    return (
      <>
        <div className="row p-4">
          <div className="col-12 col-xl-6 col-lg-6 col-md-8 col-sm-8 offset-xl-3 offset-lg-3 offset-md-2 offset-sm-2 offset-0">
            <div className="row mb-3">
              <div className="col-12">
                <div className="p-2 text-center" style={{ fontSize: "1.3rem", fontWeight: "400" }}>Rolling Amount</div>
                <div
                  className="p-2 text-center"
                  style={{ fontSize: "1.3rem", fontWeight: "600", color: "#000 !important" }}
                >
                  {/* {!pointsDetails.isLoading && pointsDetails.data?.point?.points} */}
                  {new Intl.NumberFormat().format(rolling.isLoading && rolling?.data?.rollAmo)}
                  <div className="col-12 col-lg-6 col-md-6 col-sm-6 pt-2 offset-xl-3 offset-lg-3 offset-md-2 offset-sm-2 offset-0">
                        <a href="#">
                          <div onClick={(e) => endRolling(e)} className="btn btn-primary btn-lg w-100"> END ROLLING</div>
                        </a>
                      </div>
                </div>
                {errmsg && (
                  <div
                    style={{ marginTop: "30px" }}
                    className="alert alert-danger"
                  >
                    {errmsg}
                  </div>
                )}
                {successMsg && (
                  <div
                    style={{ marginTop: "30px" }}
                    className="alert alert-success"
                  >
                    {successMsg}
                  </div>
                  // <div className="alert alert-success">{successMsg}</div>
                )}
              </div>
              <div>

              </div>

              {/* <div className="col-6">
                <div className="cashierNewTitel text-center">Rank</div>
                <div
                  className="cashierNewTitel text-center"
                  style={{ color: "#25ac5b" }}
                >
                {!pointsDetails.isLoading && pointsDetails.data?.point?.rank}
                </div>
              </div> */}
            </div>
            

          </div>
          
        </div>
        

      </>
    );
  }
  const BindStateToProps = (state=>{
    return {
        siteImages : state.siteImages
    }
  })
  export default connect(BindStateToProps)(Rolling);
// export default connect()(Points);