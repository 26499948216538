import { useQuery } from "@tanstack/react-query";
import APIClient from "../providers/apiClient";
import ms from "ms";

//for poya notice
export const useTableConfigNotice = () => {
  const apiClient = new APIClient("/siteapi/notice");
  return useQuery({
    queryKey: ["configNotice"],
    queryFn: apiClient.getAll,
    // staleTime: ms("2m"),
  });
};

// export const useTableConfigLowbalance = () => {
//   const apiClient = new APIClient("/siteapi/lowbalance");
//   return useQuery({
//     queryKey: ["configNotice"],
//     queryFn: apiClient.getAll,
//     // staleTime: ms("2m"),
//   });
// };

export const useTableConfigGames = () => {
  const apiClient = new APIClient("siteapi/game-images");
  return useQuery({
    queryKey: ["configGames"],
    queryFn: apiClient.getAll,
    // staleTime: ms("2m"),
  });
};

export const useTableConfig = () => {
  const apiClient = new APIClient("/getAllGames");
  return useQuery({
    queryKey: ["tableConfig"],
    queryFn: apiClient.getAll,
    // staleTime: ms("2m"),
  });
};

// for low balance
export const useLowBalance = () => {
  const apiClient = new APIClient("/siteapi/lowbalance");
  return useQuery({
    queryKey: ["lowbalance"],
    queryFn: apiClient.getAll,
    // staleTime: ms("2m"),
  });
};

export const useInrToUsd = () => {
  const apiClient = new APIClient("/siteapi/getgeneralconfig/inr_usd_rate");
  return useQuery({
    queryKey: ["lowbalance"],
    queryFn: apiClient.getAll,
    // staleTime: ms("2m"),
  });
};
