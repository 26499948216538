import { IMAGE_BUCKET_URL } from "../../DataStore/ApiConstant";
// import WhatsappButton from "../Cashier/whatsappButton";
import ImportantNotice from "./ImportantNotice";
import TranslatesContent from "../../DataStore/Languages/translatesContent";
import BonusOffer from "../TransactionCommon/BonusOffer";
import { CONTACT_DETAILS } from "../../constants/index";

const CrediCardPayment = () => {
  return (
    <>
      <div
        className="tab-pane fade"
        id="v-pills-messages"
        role="tabpanel"
        aria-labelledby="v-pills-messages-tab"
      >

              <div className="row d-flex justify-content-center">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="cashierNewTitel text-center"
                >
                </label>
                <div className="col-md-2 col-4 py-2 text-center">
                  <img
                    src={IMAGE_BUCKET_URL + "visa_icon_new.png"}
                    class="img-fluid"
                    alt=""
                  ></img>
                </div>
                <div className="col-md-2 col-4 py-2 text-center">
                  <img
                    src={IMAGE_BUCKET_URL + "master_card_icon_new.png"}
                    class="img-fluid"
                    alt=""
                  ></img>
                </div>
                <div className="col-md-2 col-4 py-2 text-center">
                  <img
                    src={IMAGE_BUCKET_URL + "amex_icon_new.png"}
                    class="img-fluid"
                    alt=""
                  ></img>
                </div>
              </div>
              <div className="row">
              <div className="col-12 col-lg-7 col-md-7 col-sm-7 col-xl-7 offset-xl-4 offset-lg-4 offset-md-4 offset-sm-4 offset-0">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="row">
                      <div className="col-12">
                        <div className="row text-center">
                          <div className="col-12 mb-2 mb-lg-3">
                            <label
                              htmlFor="exampleFormControlInput1"
                              className="cashierNewTitel"
                            >
                              <TranslatesContent contentKey="for-credit-card-please" />
                            </label>
                          </div>
                          <div className="col-12">
                            <div className="">
                                <a href={`https://wa.me/${CONTACT_DETAILS.deposit.whatsapp}`} target="_blank">
                                  <div className="btn btn-primary">
                                    <i
                                      className="fa-brands fa-whatsapp"
                                      style={{ fontSize: "25px" }}
                                      s
                                    ></i>{" "}
                                    {CONTACT_DETAILS.deposit.whatsapp}
                                  </div>
                                </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          {/* <WhatsappButton /> */}
      </div>
    </>
  );
};

export default CrediCardPayment;
