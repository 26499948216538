import { useState } from "react";
import { connect } from "react-redux";
import { useHistory, Link } from "react-router-dom/cjs/react-router-dom";
import { RequestLogin } from "../../../DataStore";
import TranslatesContent from "../../../DataStore/Languages/translatesContent";
import { useAtom } from "jotai";
import { redirectToCashier } from "../../../DataStore/atom";

const LoginForm = (props) => {
  const changeContent = props.changeContent;
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const history = useHistory();
  const [loginErr, setLoginErr] = useState("");
  const [loginSuccess, setLoginSuccess] = useState("");

  // const [redirectCashier, setRedirectToCashier] = useAtom(redirectToCashier);

  const submitLogin = (e) => {
    setSpLoad(true);
    e.preventDefault();
    const data = { username: username, password: password };
    props.loginRequest(data, (respond) => {
      if (respond.data === "done") {
        setLogin();
      } else {
        setLoginErr(respond.data);
        setLoginSuccess("");
        setSpLoad(false);
      }
    });
  };
  const [spLoad, setSpLoad] = useState(false);
  // console.log(props.openGeneralModal)

  const setLogin = () => {
    setLoginErr("");
    setLoginSuccess("Logged in Successfully");
    localStorage.setItem('login', username);
    localStorage.setItem("defaultUri", 'https://storage.googleapis.com/itonecdn/ballysimgWeb/');

    // localStorage.setItem("login", username);
  //  console.log(redirectCashier);
   const localRedirect = localStorage.getItem("redirectCashier")
    setTimeout(() => {
      // setRedirectToCashier()
      localStorage.removeItem("redirectCashier")
      localRedirect ? history.push(localRedirect) : 
      history.push("/lobby")
      setSpLoad(false);
    }, 1000);
  };

  return (
    <div className="row">
      <div className="col-12 col-lg-6 col-md-6 col-sm-6 ">
        <div className="col-12 text-center">
          <i className="fas fa-lock fa-4x"></i>
        </div>
        <div className="col-12 mainTitel">
          <TranslatesContent contentKey="log-in" />
        </div>
        <form onSubmit={(e) => submitLogin(e)}>
          <div className="form-group">
            <label htmlFor="exampleInputEmail1">
              <TranslatesContent contentKey="username" />
            </label>
            <input
              type="text"
              value={username}
              onChange={(e) => {
                setUsername(e.target.value);
              }}
              className="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
            />
          </div>
          <div className="form-group">
            <label htmlFor="exampleInputPassword1">
              <TranslatesContent contentKey="password" />
            </label>
            <input
              value={password}
              type="password"
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              className="form-control"
              id="exampleInputPassword1"
            />
          </div>

          <button className="frontButton3" disabled={spLoad}>
            {spLoad ? (
              "Please wait"
            ) : (
              <TranslatesContent contentKey="login-login" />
            )}
          </button>
        </form>

        <div className="col-12" style={{ marginTop: "15px" }}>
          <h2
            onClick={(e) => {
              e.preventDefault();
              changeContent("password-reset");
            }}
            style={{
              cursor: "pointer",
              textAlign: "left",
              fontSize: "15px",
              fontWeight: "600",
              fontFamily: "Roboto",
              marginBottom: "10px",
            }}
          >
            <TranslatesContent contentKey="forgot-password" />
          </h2>
        </div>
        <div className="col-12">
          <h2
            onClick={(e) => {
              e.preventDefault();
              props.closePopup()
              props.openGeneralModal("agreement")
            }}
            style={{
              cursor: "pointer",
              textAlign: "left",
              fontSize: "15px",
              fontWeight: "600",
              fontFamily: "Roboto",
              marginBottom: "10px",
            }}
          >
            <TranslatesContent contentKey="legal-info" />
          </h2>
        </div>
        <div className="login-err" style={{ color: "red", fontSize: "12px" }}>
          {loginErr && loginErr}
        </div>
        <div
          className="loginSuccess"
          style={{ color: "green", fontSize: "12px" }}
        >
          {loginSuccess && loginSuccess}
        </div>
      </div>
      <div className="col-12 col-lg-6 col-md-6 col-sm-6 ">
        <div className="col-12 text-center">
          <i className="fas fa-key fa-4x"></i>
        </div>
        <div className="col-12 mainTitel">
          <TranslatesContent contentKey="new-member" />
        </div>
        <button
          className="frontButton3 col-12"
          onClick={() => changeContent("register")}
        >
          <TranslatesContent contentKey="login-join" />
        </button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loginState: state.login,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loginRequest: (data, callback) => {
      dispatch(RequestLogin(data, callback));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
