import { useEffect, useState } from "react";
import { countryList, fetchImages, getVersions } from "./DataStore";
import { connect } from "react-redux";
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom/cjs/react-router-dom";
import FirstPage from "./Components/Layouts/FirstPage";
import Lobby from "./Components/Layouts/Lobby";
import { useRef } from "react";
import CashierModalWrapper from "./Components/Modal/CashierModal/CashierModalWrapper";
import Cashier from "./Components/Layouts/Cashier";
import Promotions from "./Components/Layouts/Promotions";
import { useAtom } from "jotai";
import { promotion } from "./DataStore/atom";
import { UserData } from "./DataStore";

const Home = ({
  userData,
  siteImages,
  fetchImages,
  countryList,
  fetchCountries,
  versions,
  getVersions,
}) => {
  // get all site images
  useEffect(() => {
    fetchImages();
    fetchCountries();
    getVersions();
  }, []);

  useEffect(() => {
    if (!userData.isLoading) {
      const userType = userData?.data?.data1?.user_type;
      if (userType !== "demo") {
        setCashierType("deposit");
      } else {
        setCashierType("transfer");
      }
    }
  }, [userData]);
  const gameWrapperArea = useRef(null);
  const [, setPromo] = useAtom(promotion);

  const [selectedGameAreaType, setSelectedGme] = useState("liveCasino");

  const setSelectedGameType = (type) => {
    if (type == "promotions") {
      setPromo("");
    }
    setSelectedGme(type);
    gameWrapperArea.current?.scrollIntoView({ behavior: "smooth" });
    // window.scrollTo(0 , document.getElementById('game-areas-wrapper').offsetTop)
  };

  const [promoSelected, setPromoSelected] = useState("");

  // modal controls
  const [modalStatus, setModalStatus] = useState("closed");
  const [modalType, setModalType] = useState("login");

  const [generalModalStatus, setGeneralModalStatus] = useState("closed");
  const [generalModalType, setGeneralModalType] = useState("contact");

  const [opencashier, setOpenCashier] = useState(false);
  // const [cashierType, setCashierType] = useState("deposit");
  const [cashierType, setCashierType] = useState();

  const openPopup = (type) => {
    setModalType(type);
    setModalStatus("active");
  };

  const openGeneralModal = (type) => {
    setGeneralModalType(type);
    setGeneralModalStatus("active");
  };

  const closePopup = () => {
    setModalStatus("closed");
    setGeneralModalStatus("closed");
  };

  const changeContent = (type) => {
    setModalType(type);
  };

  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <FirstPage
            setOpenCashier={setOpenCashier}
            selectedGameAreaType={selectedGameAreaType}
            setSelectedGameType={setSelectedGameType}
            modalStatus={modalStatus}
            modalType={modalType}
            openLoginPopup={openPopup}
            closePopup={closePopup}
            changeContent={changeContent}
            generalModalStatus={generalModalStatus}
            generalModalType={generalModalType}
            openGeneralModal={openGeneralModal}
            gameWrapperArea={gameWrapperArea}
            openPopup={openPopup}
          />
        </Route>
        <Route exact path="/login">
          <FirstPage
            setOpenCashier={setOpenCashier}
            selectedGameAreaType={selectedGameAreaType}
            setSelectedGameType={setSelectedGameType}
            modalStatus={modalStatus}
            modalType={modalType}
            openLoginPopup={openPopup}
            closePopup={closePopup}
            changeContent={changeContent}
            generalModalStatus={generalModalStatus}
            generalModalType={generalModalType}
            openGeneralModal={openGeneralModal}
            gameWrapperArea={gameWrapperArea}
            openPopup={openPopup}
          />
        </Route>
        <Route exact path="/join">
          <FirstPage
            setOpenCashier={setOpenCashier}
            selectedGameAreaType={selectedGameAreaType}
            setSelectedGameType={setSelectedGameType}
            modalStatus={modalStatus}
            modalType={modalType}
            openLoginPopup={openPopup}
            closePopup={closePopup}
            changeContent={changeContent}
            generalModalStatus={generalModalStatus}
            generalModalType={generalModalType}
            openGeneralModal={openGeneralModal}
            gameWrapperArea={gameWrapperArea}
            openPopup={openPopup}
          />
        </Route>
        <Route exact path="/lobby">
          <Lobby
            opencashier={opencashier}
            selectedGameAreaType={selectedGameAreaType}
            setSelectedGameType={setSelectedGameType}
            modalType={modalType}
            modalStatus={modalStatus}
            openPopup={openPopup}
            closePopup={closePopup}
            generalModalStatus={generalModalStatus}
            generalModalType={generalModalType}
            openGeneralModal={openGeneralModal}
            gameWrapperArea={gameWrapperArea}
            setCashierType={setCashierType}
          />
        </Route>
        <Route exact path="/home">
          <Lobby
            opencashier={opencashier}
            selectedGameAreaType={selectedGameAreaType}
            setSelectedGameType={setSelectedGameType}
            modalType={modalType}
            modalStatus={modalStatus}
            openPopup={openPopup}
            closePopup={closePopup}
            generalModalStatus={generalModalStatus}
            generalModalType={generalModalType}
            openGeneralModal={openGeneralModal}
            gameWrapperArea={gameWrapperArea}
            setCashierType={setCashierType}
          />
        </Route>
        <Route exact path="/cashier">
          <Cashier
            openPopup={openPopup}
            openLoginPopup={openPopup}
            selectedGameAreaType={selectedGameAreaType}
            setSelectedGameType={setSelectedGameType}
            generalModalStatus={generalModalStatus}
            closePopup={closePopup}
            generalModalType={generalModalType}
            openGeneralModal={openGeneralModal}
            gameWrapperArea={gameWrapperArea}
            cashierType={cashierType}
            setCashierType={setCashierType}
            countryList={countryList}
          />
        </Route>
        <Route exact path="/home/cashier">
          <Cashier
            openPopup={openPopup}
            openLoginPopup={openPopup}
            selectedGameAreaType={selectedGameAreaType}
            setSelectedGameType={setSelectedGameType}
            generalModalStatus={generalModalStatus}
            closePopup={closePopup}
            generalModalType={generalModalType}
            openGeneralModal={openGeneralModal}
            gameWrapperArea={gameWrapperArea}
            cashierType={cashierType}
            setCashierType={setCashierType}
          />
        </Route>
        <Route exact path="/promotions">
          <Promotions
            // openPopup={openPopup}
            openLoginPopup={openPopup}
            selectedGameAreaType={selectedGameAreaType}
            setSelectedGameType={setSelectedGameType}
            generalModalStatus={generalModalStatus}
            // closePopup={closePopup}
            generalModalType={generalModalType}
            openGeneralModal={openGeneralModal}
            gameWrapperArea={gameWrapperArea}
            setCashierType={setCashierType}
          />
        </Route>
        <Route exact path="/logout">
          <Lobby
            opencashier={opencashier}
            selectedGameAreaType={selectedGameAreaType}
            setSelectedGameType={setSelectedGameType}
            modalType={modalType}
            modalStatus={modalStatus}
            openPopup={openPopup}
            closePopup={closePopup}
            generalModalStatus={generalModalStatus}
            generalModalType={generalModalType}
            openGeneralModal={openGeneralModal}
            gameWrapperArea={gameWrapperArea}
            setCashierType={setCashierType}
          />
        </Route>
        <Route exact path="/cashier/:type">
          <Cashier
            navItems={"crypto"}
            openPopup={openPopup}
            openLoginPopup={openPopup}
            selectedGameAreaType={selectedGameAreaType}
            setSelectedGameType={setSelectedGameType}
            generalModalStatus={generalModalStatus}
            closePopup={closePopup}
            generalModalType={generalModalType}
            openGeneralModal={openGeneralModal}
            gameWrapperArea={gameWrapperArea}
            cashierType={cashierType}
            setCashierType={setCashierType}
          />
        </Route>
      </Switch>
    </Router>
  );
};

const mapStateToProps = (state) => {
  return {
    siteImages: state.siteImages,
    countryList: state.CountryList,
    versions: state.Versions,
    userData: state.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchImages: () => dispatch(fetchImages()),
    fetchCountries: () => dispatch(countryList()),
    getVersions: () => dispatch(getVersions()),
    getUserData: () => dispatch(UserData()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
