import { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { BaccaratResult } from "../../../DataStore";
import SingleBaccaratResultBoard from "./SingleBacResultBoard";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { IMAGE_BUCKET_URL } from "../../../DataStore/ApiConstant";

const BaccaratResultBoard = ({ baccaratResult, getBaccaratResult, config, isLoad}) => {
  const [gemeConfig, setGameConfig] = useState([]);

  const loadGame = (gameLink) => {
    window.location.replace("/livebaccarat/#" + gameLink);
  };
  useEffect(() => {
    getBaccaratResult();
  }, []);

  useEffect(() => {
    if(!isLoad ){
      if(config ){
          let data = Object.values(config)
          // console.log(data[1].bac[1].slice(1));
          setGameConfig(data[1].bac[1].slice(1) || []);
      }
    }
  },[isLoad])
  let baccaratResultData = "";
  baccaratResult
    ? (baccaratResultData = baccaratResult.data.data)
    : (baccaratResultData = "");

  return baccaratResultData === "tables not available" ? (
    <div>{baccaratResultData}</div>
  ) : (
    <div className="row">
      {baccaratResultData &&
        Object.keys(baccaratResultData).map((key, index) => {
          return (
            <div
              key={index}
              className="col-lg-4 col-lg-4 mx-0 px-1 mb-2"
              style={{ position: "relative", cursor:"pointer"  }}
              onClick={() => { if (gemeConfig && gemeConfig[index][4] !== 0) { loadGame(key)}}}
            >
              {
                 gemeConfig[index] && gemeConfig[index][4]==0 ? (
                 <div class="position-absolute top-50 start-50 translate-middle d-flex flex-column justify-content-center align-items-center tableClose tableCloseText">
                    <div class="row ">
                        <div class="tableCloseText ">Closed
                        </div>
                    </div>
                </div>) : 
              (
                <div class="content-overlay text-center pt-5">
                    <a class="hoverButton " onClick={() => loadGame(key)}>
                      PLAY
                    </a>
                </div>
              )
              }
              {/* <div
                  class="position-absolute top-50 start-50 translate-middle d-flex flex-column justify-content-center align-items-center tableClose">
                  <div class="row ">
                      <div class="tableCloseText ">Closed
                      </div>
                  </div>
              </div> */}

              {/* <a to={`/livebaccarat/${key}`} className="single-board-link"> */}
              {/* <div class="content-overlay text-center pt-5">
                <a class="hoverButton " onClick={() => loadGame(key)}>
                  PLAY
                </a>
              </div> */}
                {/* <p style={{ textAlign: "center", marginTop: "10%" }}>PLAY</p> */}
              {/* </a> */}

              <div className="row m-0 p-1 border rounded border-white">
                <div className="col-12 ms-0 p-0">
                  <SingleBaccaratResultBoard
                    index={index}
                    Tblekey={key}
                    result={baccaratResultData[key]}
                  />
                </div>
                <div className="col-12">
                  <div className="row">
                    <div
                      className="col-4 tableCountBoardName"
                      style={{ color: "white" }}
                    >
                      {gemeConfig[index] ? gemeConfig[index][1] : 'N/A'}
                      {/* {baccaratResultData[key].tablename} */}
                    </div>
                    <div className="col-8 " style={{ paddingRight: 0 }}>
                      <div
                        className="tableCountBoardLimit text-end"
                        style={{ paddingRight: 0, textDecoration: "none" }}
                      >
                        {
                        // baccaratResultData[key].min_limit &&
                        //   new Intl.NumberFormat().format(
                        //     baccaratResultData[key].min_limit
                        //   )
                        gemeConfig[index] ? gemeConfig[index][2] : 'N/A'
                          }{" "}
                        -{" "}
                        {
                        // baccaratResultData[key].max_limit &&
                        //   new Intl.NumberFormat().format(
                        //     baccaratResultData[key].max_limit
                        //   )
                        gemeConfig[index] ? gemeConfig[index][3] : 'N/A'
                          }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    baccaratResult: state.BaccaratResults,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getBaccaratResult: () => dispatch(BaccaratResult("bac")),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BaccaratResultBoard);
